@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    border: 1px solid $color-grey-2;

    .divider {
        height: auto;
        margin: 0 20px;
    }

    .purchaserDetails,
    .supplierDetails {
        flex: 1;
        display: flex;
        flex-direction: column;

        .fieldTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            border: none;
            padding: 0;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }


    }
}