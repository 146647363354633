@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .productWrapper {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 8px;
        padding: 10px;

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #ECECEC;
            cursor: pointer;

            .title {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .products {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 4px;
                }

                .productNameWrapper {
                    display: inline-flex;
                    align-items: center;
                    gap: 4px;
                }

            }

            .buttonWrapper {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }

        .quoteWrapper {
            display: flex;
            flex-direction: column;
            padding: 20px 10px;

            .content {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .contentHeader {
                    display: flex;
                    flex-direction: row;
                    gap: 48px;
                    padding: 20px 10px;
                    border-bottom: 1px solid #ECECEC;
                    align-items: center;
                    justify-content: space-between;

                    .checkBoxKeyValue {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        gap: 10px;

                        .checkBox {
                            display: flex;
                            flex-direction: row;

                            .checkboxLabel {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 24px;
                            }
                        }

                        .checkedKeyValue {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: center;
                            min-width: 110px;
                            gap: 4px;

                            .key {
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 120%;
                                color: #8D8885;
                            }

                            .value {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 120%;
                                color: #2A2522;
                                font-family: Mukta;
                            }
                        }
                    }

                    .keyValue {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        text-align: center;
                        min-width: 110px;
                        gap: 4px;
                        border-left: 1px solid #ECECEC;
                        padding-left: 10px;

                        .key {
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 120%;
                            color: #8D8885;
                        }

                        .value {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 120%;
                            color: #2A2522;
                            font-family: Mukta;
                        }
                    }

                    .contentHeaderIcons {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        align-items: center;
                    }
                }

                .contentBody {
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    justify-content: space-between;
                    align-items: center;

                    .contentBodyItem {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        gap: 20px;

                        .keyValue {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: center;
                            min-width: 150px;
                            gap: 4px;
                            border-left: 1px solid #ECECEC;
                            padding-left: 10px;

                            .key {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 120%;
                                color: #8D8885;
                            }

                            .value {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 120%;
                                color: #2A2522;
                                font-family: Mukta;
                            }
                        }

                        .keyValue:first-of-type {
                            border-left: none;
                            padding-left: 0;
                        }
                    }

                    .contentBodyIcons {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 140%;
                        color: #8D8885;
                    }
                }
            }
        }
    }
}