@import '@styles/resources';

.formWrapper {
    display: flex;
    flex-direction: column;
    gap: 36px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 23px;
        padding: 21px;
        border-radius: 8px;
        border: 1px solid #ECECEC;

        .rowWrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 23px;
        }
    }

    .actionButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

}