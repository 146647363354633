@import '@styles/resources';

.mainWrapper {
    position: fixed;
    left: calc(100% - 700px - 490px);
    top: 20%;
    z-index: 50;
    overflow-y: auto;
    max-height: 60vh;


    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    .wrapper {
        width: 470px;
        background-color: #FFF;
        border-radius: 8px;
        border: 1px solid #ECECEC;
        box-shadow: 0px 0px 14.033px 0px rgba(0, 0, 0, 0.06);
        padding: 21px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
        max-height: 100%;

        .header {
            font-size: 17px;
            font-weight: 500;
            line-height: 120%;
            text-align: center;
        }

        .quoteWrapper {
            display: flex;
            flex-direction: column;
            gap: 11px;
            border-radius: 8px;
            border: 1px solid #ECECEC;
            padding: 10px 15px;
        }

        .cardWrapper {
            display: flex;
            flex-direction: column;
            gap: 11px;
            width: 100%;
            padding-bottom: 16px;
            border-bottom: 1px solid #ECECEC;

            .cardHeader {
                font-size: 16px;
                color: #000;
                font-family: Mukta;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                width: 100%;
            }

            .cardQuotePrice {
                display: flex;
                flex-direction: row;
                gap: 8px;
                justify-content: center;
                align-items: center;
                width: 100%;

                .finalQuotePrice {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 120%;
                    font-style: normal;
                    text-align: center;
                }

                .finalQuotePriceValue {
                    color: $color-red-2;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    text-align: center;
                }
            }

            .cardContent {
                display: flex;
                flex-direction: column;
                padding: 10px 20px;
                border-radius: 10px;
                align-items: flex-start;
                gap: 10px;
                border: 1px solid #ECECEC;
                width: 100%;

                .cardContentItem {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;

                    .cardContentItemHeader,
                    .cardContentItemColon,
                    .cardContentItemValue {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        flex: 1;
                        text-align: center;
                    }

                    .cardContentItemHeader {
                        text-align: left;
                    }

                    .cardContentItemValue {
                        text-align: right;
                        font-weight: 600;
                    }

                }
            }
        }
    }
}