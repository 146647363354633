@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        .title {
          display: flex;
          flex-direction: row;
          align-items: center;

          .subTitle {
            margin: 0 8px;
          }
        }
        .pocWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          float: right;
          gap: 24px;

          .uploadWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .assignButton {
            border: 1px solid $color-red-1;
            padding: 10px 20px;
          }

          .btnContainer {
            display: flex;
            gap: 8px;
          }
        }
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }
    }
  }
  .accordianContent {
    display: flex;
    .linkButton {
      background: none;
      border: 1px solid $color-red-1;
    }
    .docContainer {
      padding: 0px 24px;
      background: $color-grey-1;
    }

    .docTitle {
      color: $color-slate-brown-1;
    }
    .docContainer,
    .docContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-grey-1;
      color: $color-slate-brown-1;
      position: relative;
      .textWrapperNew {
        padding: 16px 24px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .uploadDocWrapper {
          display: flex;
          flex-direction: row;
          gap: 19px;
          align-items: flex-start;
          color: $color-slate-brown-1;
        }
      }
    }
    .docContent {
      padding: 16px 24px;
      padding: 0;
      display: flex;
      flex-grow: 1 !important;
      border-radius: 0;
      border-spacing: 0;
      overflow: hidden;
    }
    .textWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: c;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        align-self: stretch;
        margin-left: auto;
        border-left: 1px solid $color-grey-1;
        padding-left: 16px;
      }
    }
    .textWrapperNew {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.contentChip,
.linkButton {
  text-align: center;
  margin: 0 auto;
}
.supplierWrap {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.iconWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.linkButton {
  font-size: 16px;
  background: none;
  color: red;
}
.linkButton:hover {
  background: none;
}

.underlineOnHover:hover {
  text-decoration: underline;
}