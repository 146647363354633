.formWrapper {
    flex: 1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .actionBtnWrapper {
        margin-top: auto;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}