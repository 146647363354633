@import '@styles/resources';

.headerWrapper {
  display: flex;
  text-align: right;
  justify-content: space-between;
  margin-bottom: 16px;
  .componeBtn {
    width: 200px;
    text-align: right;
  }

  .emailChipWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.senderEmail {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.note {
  text-align: center;
}

.communicationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .communcationPhotosFilter {
      display: flex;
      flex-direction: row;
      gap: 16px;
  }
}