@import '@styles/resources';

.productWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  gap: 24px;

  .productTabContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.productIndividualSkeletonWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
  padding-inline: 72px;

  .productIndividualSkeletonCard {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    padding: 16px;

    .productIndividualSkeletonCardLeft {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .productIndividualSkeletonCardLeftDetails {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .productIndividualSkeletonCardRight {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .productIndividualSkeletonFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .productIndividualSkeletonFooterTabs {
      display: flex;
      flex-direction: row;
      gap: 16px;
      border-bottom: 1px solid $color-grey-2;
      padding: 16px;
    }

    .productIndividualSkeletonFooterDetailsTab {
      display: flex;
      flex-direction: row;
      gap: 16px;
      border-bottom: 1px solid $color-grey-2;
      border-radius: 8px;
      padding: 16px;
    }

    .productIndividualSkeletonFooterDetails {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .productIndividualSkeletonFooterSidebar {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-right: 1px solid $color-grey-2;
        padding: 16px;
      }

      .productIndividualSkeletonFooterData {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border: 1px solid $color-grey-2;
        border-radius: 8px;
        padding: 16px;
        width: 100%;

        .productDetails {
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;
          justify-content: space-between;

          .productDetailsObject {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }
}