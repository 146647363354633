@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .contentWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 23px;

        .actionButtons {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
        }
    }


}