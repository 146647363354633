@import '@styles/resources';

.partiesFormWrapper {
    padding-block: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    flex: 1;

    .purchaserContainer,
    .supplierContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 16px;
        gap: 16px;
        background: $color-white;
        border-radius: inherit;
        flex: 1;
        margin-bottom: 16px;

        .nameFieldWrapper {
            display: flex;
            align-items: center;
            gap: 24px;
        }
    }

    .formActionWrapper {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;
    }
}