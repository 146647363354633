@import '@styles/resources';

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    padding-block: 24px;
    flex: 1;

    .boxWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 28px;
        padding: 16px;

        .fieldWrapper {
            display: flex;
            align-items: center;
            flex: 1;
            gap: 10px;
        }

        .blWrapper {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 16px;

            .blRadioWrapper {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                gap: 8px;
                padding-left: 50px;
            }
        }

        .remarkBoxContainer {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 16px;
            gap: 24px;
            min-height: 134px;
            border: 1px solid $color-grey-2;
            border-radius: 8px;
        }
    }

    .actionButtonWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actionButtonContainer {
            display: flex;
            gap: 16px;
            align-items: center;
        }
    }
}