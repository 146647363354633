@import '@styles/resources';

.container {
    width: 100%;
}

.mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 23px;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    padding: 21.05px;
    width: 100%;

    .headerWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 3.5px;
        border-bottom: 1px solid #ECECEC;

        .headerText {
            font-size: 17.5px;
            color: var(--Elchemy-Primary-Slate-Brown, #2A2522);
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }

        .headerIcons {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;
        gap: 23px;
        align-items: flex-start;
        width: 100%;

        .rowWrapper {
            display: flex;
            flex-direction: row;
            gap: 23px;
            width: 100%;

            >* {
                flex: 1 1 0;
            }
        }

        .rowWrapperHalf {
            display: flex;
            flex-direction: row;
            gap: 23px;
            width: 100%;

            >* {
                width: 48%;
            }
        }
    }
}