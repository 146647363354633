@import '@styles/resources';

.supplierCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  height: 181px;
  content-visibility: auto;
  contain-intrinsic-size: 181px;

  .supplierCardHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    flex: 1;
    .supplierCardHeader {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 4px;
      .supplierId {
        font-family: $Mukta;
        font-style: normal;
        font-weight: $font-weight-400;
        font-size: $fs-18;
        color: $color-grey-6;
      }
      .chipWrapper {
        display: flex;
        gap: 15px;
        span {
          margin-top: 2px;
          display: flex;
          gap: 10px;
        }
      }
    }
  }
  .supplierCardFooterWrapper {
    display: flex;
    align-items: stretch;
    padding: 16px 24px;
    flex: 1;
    .supplierCardFooterDetailWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      gap: 4px;
      flex-grow: 1;
      .supplierDetailTitle {
        font-family: $Mukta;
        font-style: normal;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        line-height: 19px;
        color: $color-grey-6;
      }
      .supplierDetailText {
        font-family: $Mukta;
        font-style: normal;
        font-weight: $font-weight-400;
        font-size: $fs-18;
        line-height: 22px;
        color: $color-slate-brown-1;
      }
    }
  }
  .supplierCardLink {
    text-decoration: none !important;
    cursor: pointer;
  }
}
