@import '@styles/resources';

.accordionMainHeader {
    margin-bottom: 16px;
    background-color: $color-white;

    .accordionHeader {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;

        .accordionTitle {
            font-family: $Mukta;
            font-weight: $font-weight-500;
            font-size: $fs-14;
            text-transform: uppercase;
            color: $color-grey-6;
        }

        .accordionDivider {
            width: 100%;
            flex: 1;
        }
    }
}

.accordionContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $color-white;
    box-shadow: $box-shadow-1;
    border-radius: 8px;
    margin-top: 16px;

    .accordianHeaderParent {
        padding: 0 24px;
        border-bottom: 1px solid $color-grey-2;

        .accordionHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: $color-white;
            //border-bottom: 1px solid $color-grey-2;
            width: 100%;
            padding: 16px 0;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;


                .wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 8px;
                }

                .pocWrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 24px;

                    .uploadWrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }

                .divider {
                    height: 38px;
                    border-right: 1px solid $color-grey-1;
                }

                .editButton {
                    color: $color-slate-brown-1;
                }

                .uploadButton {
                    color: $color-red-1;
                    border: 1px solid $color-red-1;
                    padding: 10px 20px;
                    border-radius: 8px;
                }
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .textWrapper,
        .textWrapperNew {
            padding: 16px 24px;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .textWrapperNew {
            justify-content: flex-end;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px
        }
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper,
    .textWrapperNew {
        padding: 16px 24px;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .textWrapperNew {
        justify-content: flex-end;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .textWrapper,
        .textWrapperNew {
            padding: 16px 24px;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .textWrapperNew {
            justify-content: flex-end;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }
}

.underlineOnHover:hover {
    text-decoration: underline;
}