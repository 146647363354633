@import '@styles/resources';

.imageWrapper {
  margin: 15px 0px;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .delete {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .confirmDelete {
      display: none;
    }

    .cancelDelete {
      display: none;
    }
  }
}

.imageContainer {
  position: relative;
  display: inline-block;

  img {
    object-fit: cover;
    width: 100%;
    display: block;
    transition: opacity 0.3s ease;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.2);
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    text-align: center;
  }

  &:hover .overlay {
    opacity: 1;
  }
}

.stuffingPhotosContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stuffingPhotos {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

@media (max-width: $sm-max) {
  .images {
    width: 100% !important;
    height: auto !important;
  }
}
