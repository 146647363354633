@import '@styles/resources';

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 635px;
  max-height: 100%;
  //min-height: 75%;
  overflow: auto;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .modalContent {
    padding: 32px 16px;
    overflow-y: scroll;
    height: 100%;

    .contentWrapper {
      border-radius: 8px;
      border: 1px solid $color-grey-4;
      padding: 16px;
      margin-bottom: 12px;
      box-shadow: $box-shadow-1;

      .rowWrapper {
        flex: 1;
        display: flex;
        gap: 0.2rem;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-top: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        // gap: 5%;
        .dataWrapper {

          // flex-basis: 45%;
          // max-width: 45%;
          .chipWrapper {
            display: flex;
            margin-top: 8px;

            .chip {
              margin-right: 5px;
            }
          }

          .inputWrapper {
            margin-bottom: 6px;
          }

          .viewDelete {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .rowWrapper1 {
        flex: 1;
        display: flex;
        // align-items: center;
        margin-bottom: 12px;
        justify-content: space-between;

        // gap: 5%;
        &:last-child {
          margin-bottom: 0;
        }

        .inputWrapper {
          margin-right: 24px;
        }
      }

      .documentRowWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px;

        .fileUploadState {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 12px;
          height: auto;
          background: $color-white;
          border: 1px solid $color-grey-2;
          border-radius: 8px;
        
          .fileName {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 14px;
          }
        
          .deleteButton {
            border-left: 1px solid $color-grey-2;
            padding: 0 10px;
            cursor: pointer;
          }
        }
        
      }
    }
  }
}

.modalFooter {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: $color-white;
  box-shadow: $box-shadow-1;
  height: 74px;

  &>button {
    flex: 1;

    &:first-child {
      margin-right: 16px;
    }
  }
}