@import '@styles/resources';

.partiesWrapper {
  display: flex;
  flex-direction: column;

  .partiesContainer {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    margin-top: 16px;

    .parties {
      padding: 16px 0px 0px 24px;
      border-left: 5px solid $color-red-2;
    }

    .fieldTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .skeletonWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .divider {
      border: 3px solid $color-red-2;
      height: 200px;
      margin: 0 20px;
    }
  }
}

.detailWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-grey-1;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px #0000000f;

  .detailsTitle {
    background-color: $color-grey-1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-left: 24px;
    height: 60px;
    .editButton {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .detailContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 24px;

    .rowWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 100px;
      padding: 8px 0;
      border-bottom: 2px solid $color-grey-1;

      &:last-child {
        border-bottom: none;
      }

      .fieldWrapper {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 4px;

        .fieldLabel {
          font-size: $fs-18;
          min-width: 250px;
        }

        .fieldValueContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: normal;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: $fs-18;
        }
      }
    }

    .invoice {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .invoiceButton {
        padding: 0 8px;
        width: fit-content !important;
      }

      .fileInvoiceIcon {
        cursor: pointer;
      }
    }
  }
}

.itemTable {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paymentDetailsTableWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 2px solid $color-grey-1;
  padding-bottom: 8px;
}

.paymentDetailHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;

  .paymentDetailHeaderLabel {
    color: $color-red-2;
    font-weight: bold;
    font-size: $fs-18;
  }
}

.estimateHeader {
  display: grid;
  grid-template-columns: 0.4fr 1.2fr 1.2fr 1fr;
  gap: 8px;

  .estimateHeaderLabel {
    color: $color-red-2;
    font-weight: bold;
    font-size: $fs-18;
  }
}

.itemHeader {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  gap: 8px;
}
.itemHeaderlabel {
  color: $color-red-2;
  font-weight: bold;
  font-size: $fs-18;
}

.itemTotalHeader {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  gap: 8px;

  .itemTotalDetails {
    font-weight: bold;
  }

  .itemTotalAmount {
    font-weight: bold;
    line-height: 24px;
  }

  .itemTotalAmountInWords {
    font-weight: bold;
  }
}

.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .itemDescription {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    color: $color-grey-6;
  }

  .itemPackagingDetails {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    color: $color-grey-6;
  }

  .itemOrigin {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    color: $color-grey-6;
  }

  .itemHSCode {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    color: $color-grey-6;
  }

  .itemPSS {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    color: $color-grey-6;
  }
}

.chipCenter {
  transform: translateX(-20%);
}

.notAvailable {
  font: inherit;
  font-size: $fs-18;
  font-style: italic !important;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
    font-weight: bold;
  }

  50% {
    opacity: 0;
    font-weight: bold;
  }
}

.filtered {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: 3;
}

@media (max-width: $sm-max) {
  .partiesContainer {
    display: block !important;
    .parties {
      margin-bottom: 12px;
    }
  }
  .rowWrapper {
    display: block !important;
    .fieldWrapper {
      display: block !important;
    }
  }
  .detailContainer {
    padding: 12px !important;
    .itemCardContainer {
      border: 1px solid $color-grey-1;
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 10px;
      .itemCardInner {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
      .itemDescription {
        font-size: $fs-14;
        display: flex;
        flex-direction: row;
        color: $color-grey-6;
      }
    }
    .itemTotalDetails {
      font-weight: bold;
    }

    .itemTotalAmount {
      font-weight: bold;
      line-height: 24px;
    }

    .itemTotalAmountInWords {
      font-weight: bold;
    }
  }
  .estimateHeader {
    display: grid;
    grid-template-columns: 5fr 7fr 4fr;
    gap: 8px;
  }
  .paymentDetailHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
  }
}
