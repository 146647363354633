@import '@styles/resources';

.POCSkeletonCardWrapper {
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    width: fit-content;

    .POCSkeletonCardDetails {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}