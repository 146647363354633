@import '@styles/resources';

.mainWrapper {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 740px;
    background: $color-white-3;
    box-shadow: $box-shadow-1;
    overflow: hidden;
    padding: 19.3px 21px 30px 21px;
    gap: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid #E2E2E2;

        .quoteHeading {
            color: #2A2522;
            font-size: 28.066px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        .closeIcon {
            color: red;
            width: 27px;
            height: 27px;
        }

    }
}