@import '@styles/resources';

.customerEnquiryCardContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    flex-shrink: 0;
    padding: 16px 24px;
    width: 100%;

    .customerEnquiryCardHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #F1F2F2;
        padding-bottom: 12px;

        .customerEnquiryHeaderIdDate {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .enquiryDivider {
                height: auto;
            }
        }
    }

    .customerEnquiryCardProductsWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        border-bottom: 1px solid #F1F2F2;
        padding-bottom: 12px;

        .customerEnquiryCardProducts,
        .customerEnquiryCardProductsDetails {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .customerEnquiryViewEnquiry {
        width: 100%;

        .customerEnquiryCardViewEnquiryButton {
            width: 100%;
        }
    }
}

.internalEnquiryCardContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: flex-start;
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding: 16px 24px;
    width: 45%;

    .internalEnquiryCardHeader {
        display: flex;
        flex-direction: row;
        background: #FCFCFC;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #F1F2F2;
        padding-bottom: 12px;

        .internalEnquiryCardHeaderSelectWrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }

    .internalEnquiryCardProductsWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        border-bottom: 1px solid #F1F2F2;
        padding-bottom: 12px;

        .internalEnquiryCardProducts {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }


    .internalEnquiryRemarksClosedWrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 12px;

        .internalEnquiryRemarksClosed {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            gap: 8px;
        }

        .downloadButton {
            align-self: flex-end;
        }

    }

    .internalEnquiryRemarkOpenWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;

        .internalEnquiryRemarksOpen {
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            width: 100%;
            padding: 16px;
            border: 1px solid #E2E2E2;
            background-color: $color-white-3;
            gap: 8px;

            .internalEnquiryRemarksLabel {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .additionalRemarks {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                font-weight: 500;
                align-items: start;
                justify-content: space-between;
            }
        }

        .downloadButton {
            align-self: flex-end;
        }
    }


}

.selectWrapper {
    flex: unset;
    min-width: 150px;
}

@media (min-width: $sm-min) and (max-width: $md-max) {
    .customerEnquiryCardContainer {
        .customerEnquiryCardHeader {
            .customerEnquiryHeaderIdDate {
                flex-direction: column;
                gap: 8px;
            }
        }

        .customerEnquiryCardProductsWrapper {
            .customerEnquiryCardProducts {
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
            }

            .customerEnquiryCardProductsDetails {
                flex-direction: row;
            }
        }
    }
}