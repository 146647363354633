@import '@styles/resources';

.boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    padding: 16px;

    .remarkBoxContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 16px;
        gap: 24px;
        border: 1px solid $color-grey-2;
        border-radius: 8px;

        .fieldTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            margin-bottom: 10px;
        }

        .fieldWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .remarkBoxContainer {
        .fieldWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 24px;

            .remarkListContainer {
                margin-left: 16px;
            }

            .remarkListContainerNoMargin {
                margin-left: 0px;
            }
        }
    }
}