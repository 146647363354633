@import '@styles/resources';

.headerWrapper,
.btnWrapper {
  display: flex;
  justify-content: space-between;
}

.iconRed {
  color: red;
}

.documentContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;

  .documentFilters {
      display: flex;
      flex-direction: row;
      gap: 16px;
  }
}