@import '@styles/resources';

.modalWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin: auto;
    outline: none;
    background-color: $color-white;
    border-radius: 8px;
    border: 1px solid $color-grey-2;
    min-width: 450px;

    .modalHeader {
        padding: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: $color-grey-1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    .modalFooterButtons {
        box-shadow: $box-shadow-1;
        margin-top: 25px;
        padding: 16px;
        display: flex;
        align-items: center;
        gap: 8px;

        &>button {
            flex: 1;

            &:first-child {
                margin-right: 16px;
            }
        }
    }

    .formWrapper {
        width: 100%;

        .modalContent {
            padding: 16px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .modalSelectLabel {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}