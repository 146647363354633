@import '@styles/resources';

.orderListingCardSkeletonWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    margin: 12px;
    background: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    content-visibility: auto;
    contain-intrinsic-size: 302px;
    box-shadow: $box-shadow-1;

    @media (min-width: 768px) {
        padding: 24px;
        margin: 16px;
    }

    @media (min-width: 1024px) {
        padding: 32px;
    }

    .orderListingCardSkeletonHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $color-grey-2;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }

        .orderListingCardSkeletonHeaderLeft {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 16px;
            width: 100%;

            @media (min-width: 768px) {
                gap: 12px;
                margin-bottom: 0;
                width: 40%;
            }
        }

        .orderListingCardSkeletonHeaderRight {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 55%;

            .divider {
                display: none;

                @media (min-width: 768px) {
                    display: block;
                    height: auto;
                    margin: 0 16px;
                }

                @media (min-width: 1024px) {
                    margin: 0 24px;
                }
            }

            @media (min-width: 768px) {
                flex-direction: row;
                gap: 16px;
            }
        }
    }

    .orderListingCardSkeletonBody {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $color-grey-2;

        @media (min-width: 1024px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }

        .orderListingCardSkeletonBodyRow {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-block: 8px;
            width: 40%;

            @media (min-width: 768px) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 24px;
                padding-block: 12px;
                width: 100%;
            }

            .orderListingCardSkeletonBodyRowItem {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                @media (min-width: 768px) {
                    gap: 12px;
                    width: 30%;
                }
            }
        }
    }

    .orderListingCardSkeletonFooter {
        padding-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 768px) {
            padding-top: 24px;
            justify-content: space-between;
        }
    }

    .divider {
        display: none;

        @media (min-width: 768px) {
            display: block;
            height: auto;
            margin: 0 16px;
        }

        @media (min-width: 1024px) {
            margin: 0 24px;
        }
    }
}