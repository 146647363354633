@import '@styles/resources';

.viewEnquiryContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .viewEnquiryWrapper {
        border-radius: 8px;
        border: 1px solid #E2E2E2;
        background: #FFF;
        padding: 24px;

        .viewEnquiryIdDateStatus {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            .viewEnquiryIdDate {
                display: flex;
                flex-direction: row;
                gap: 16px;
            }
        }

        .viewEnquiryPlaceOfDelivery {
            display: flex;
            flex-direction: column;
        }

        .viewEnquiryProducts {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
        }

        .viewEnquiryDownload {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 24px;

            .viewEnquiryDownloadButton {
                min-width: 100px;
            }
        }

        .viewEnquiryRemarksWrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 24px;

            .viewEnquiryRemarks {
                padding: 16px 20px 16px 16px;
                display: flex;
                flex-direction: column;
                gap: 24px;
                border-radius: 8px;
                border: 1px solid var(--Elchemy-Primary-Grey, #E2E2E2);
                background: var(--elchemy-white-fff, #FFF);
                align-items: flex-start;
                align-self: stretch;
            }
        }
    }
}

.enquiryDivider {
    height: auto;
}


@media (max-width: $lg-max) {
    .viewEnquiryContainer {
        .viewEnquiryWrapper {
            .viewEnquiryIdDateStatus {
                align-items: flex-start;

                .viewEnquiryIdDate {
                    flex-direction: column;
                }
            }
        }
    }
}