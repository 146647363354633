.tableWrapper {
  margin-top: 15px;
  padding-bottom: 15px;

  .buttonWrapper {
    display: flex;
    gap: 5px;

    .infoIcon {
      margin-top: 4px;
    }
  }

  .viewIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 8px;
  }
}

.sortTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.bankConfirmationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}