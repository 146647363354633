@import '@styles/resources';

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 635px;
  max-height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-1;
    border-radius: 8px;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .modalContent {
    padding: 16px;
    overflow-y: auto;

    .contentWrapper {
      border-radius: 8px;
      border: 1px solid $color-grey-4;
      padding: 16px;
      margin-bottom: 12px;
      box-shadow: $box-shadow-1;

      .rowWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .dataWrapper {
          .chipWrapper {
            display: flex;
            margin-top: 8px;

            .chip {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .sortTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}