@import '@styles/resources';

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: hidden;

    .modalContent {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: $color-white;
        flex: 1;
        padding: 16px;
        overflow: auto;
        scroll-behavior: smooth;
        gap: 16px;

        &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-track-piece {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-1;
            border-radius: 8px;
        }

        .piInvoiceDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            border: 1px solid $color-grey-1;
            border-radius: 8px;
            padding: 16px;

            .typeField {
                width: 100%;
            }
        }

        .rowWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            gap: 8px;

            .financeTDSTCS {
                display: flex;
                flex-direction: row;
                align-items: center;

                .alertgrey {
                    margin-bottom: 2px;
                }
            }

            .plusRefreshWrapper {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
            }
        }
    }

    .modalFooter {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background: $color-white;
        box-shadow: $box-shadow-1;
        height: 74px;

        &>button {
            flex: 1;

            &:first-child {
                margin-right: 16px;
            }
        }
    }
}

.paymentTerms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .paymentTermsLabel {
        font-family: $Mukta;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        color: $color-black;
    }
}