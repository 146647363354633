@import '@styles/resources';

.outlineWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  background: $color-white;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .editButton {
      cursor: pointer;
    }
  }
  .itemWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .divider {
      margin: 16px 0;
    }
    &:last-child {
      .divider {
        display: none;
      }
    }
    .rowWrapper {
      margin: 16px 0;
      display: flex;
      align-items: center;
      &:last-child {
        margin: 0;
      }
      .columnWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 6px;
        flex-basis: 33.33%;
        flex: 1;
      }
      .documentHeader {
        background-color: $color-white-1;
      }
    }
  }
  .preShipmentSampleIcon {
    font-size: 12px;
  }
}

.itemDetails {
  margin-top: 16px;
  margin-bottom: 16px;
}