@import '@styles/resources';

.enquiryWrapper {
  padding: 16px;
  background-color: $color-white;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media (min-width: 1024px) {
    padding: 32px;
  }
}

.enquiryHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-grey-2;
}

.enquiryHeader {
  display: flex;
  align-items: center;
  gap: 16px;
}

.customerEnquiryWebContainer {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    gap: 24px;
  }
}

.customerEnquiry {
  display: flex;
  gap: 24px;
  width: 100%;
}

.customerEnquiryCardWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 50%;
}

.customerEnquiryViewCreateEdit {
  flex: 1;
  min-height: 400px;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  padding: 24px;
}

.customerEnquiryMobileContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    display: none;
  }
}

.cardSkeleton {
  padding: 16px;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: $color-white;
  box-shadow: $box-shadow-1;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid $color-grey-2;
}