@import '@styles/resources';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 20px;
    gap: 23px;
}

.mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;

    .headerWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 3.5px;

        .headerText {
            font-size: 17.5px;
            color: var(--Elchemy-Primary-Slate-Brown, #2A2522);
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }

        .headerIcons {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }

    .rowWrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 23px;

        >* {
            flex: 1 1 0;
        }

        .packageWrapper {
            width: 50%;
            display: flex;
            flex-direction: row;
            gap: 8px;

            .Input {
                width: calc(100% - 120px);
            }

            .Unit {
                width: 120px;
            }
        }
    }

    .rowWrapperHalf {
        display: flex;
        flex-direction: row;
        gap: 23px;
        width: 100%;

        >* {
            width: 48%;
        }
    }
}