@import '@styles/resources';

.formWrapper {
    padding-block: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    flex: 1;

    .fieldWrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        flex: 1;

        .hiddenElement {
            width: 0%;
            visibility: hidden;
            position: absolute;
        }
    }

    .orderContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 16px;
        gap: 32px;
        background: $color-white;
        flex: 1;
        border-radius: inherit;

        .paymentTermSuffix {
            display: flex;
        }
    }

    .actionButtonWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actionButtonContainer {
            display: flex;
            gap: 16px;
            align-items: center;
        }
    }

    .rowWrapper {
        flex: 1;
        display: flex;
        // align-items: left;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        .fieldWrapper {
            margin-right: 24px;
        }

        .selectFieldWrapper {
            width: 60%;
        }

        .qualityTestWrapper {
            flex: 1;
            display: flex;
            gap: 8px;
        }

        h5 {
            margin-bottom: 12px;
        }

        .fieldWrapper1 {
            margin-right: 25px;
            width: 100%;
        }
    }
}