@import '@styles/resources';

.filterWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .fiterHeading {
        color: #2A2522;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }

    .filterContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .filter {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            min-width: 100px;
            border-radius: 8px;
            border: 1px solid #ECECEC;
            background-color: $color-white-3;
            padding: 10px;

            .filterLabel {
                font-size: 14px;
                font-weight: 600;
                line-height: 150%;
                color: #2A2522;
            }
        }
    }
}