@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  //.boxWrapper {
  // flex: 1;
  // padding: 16px;
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  .itemWrapper {
    padding: 16px;
    border: 1px solid var(--color-grey-2);
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    .titleWrapper {
      margin-bottom: 12px;
      display: flex;
      gap: 8px;
      span {
        cursor: pointer;
      }
    }
    .rateWrapper {
      margin-top: 12px;
      display: flex;
      justify-content: space-around;
      gap: 10px;
      .fieldWrapper {
        //margin-right: 25px;
      }
    }
    .rowWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      border-radius: 8px;
      // border: 1px solid var(--color-grey-2);
      // padding: 16px;
      // margin: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .fieldWrapper {
        margin-right: 24px;
      }
      .fieldWrapper1 {
        border-radius: 0;
        width: 125px;
      }
      .fieldWrapper2 {
        margin-right: 24px;
      }
    }
    .rowWrapper1 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      border-radius: 8px;
      // border: 1px solid var(--color-grey-2);
      // padding: 16px;
      // margin: 16px;
      gap: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .divider {
      margin-bottom: 8px;
    }
    .deleteWrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  .itemWrapper1 {
    margin: 16px;
    .fieldWrapper {
      margin-right: 24px;
    }
  }
  //}
}
