@import '@styles/resources';

.mainWrapper {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 620px;
  background: $color-white-1;
  box-shadow: $box-shadow-1;
  overflow: hidden;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    .actionWrapper {
      display: flex;
      gap: 16px;
    }
  }

  .mainSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    flex: 1;
    overflow: auto;
    padding: 8px 0;

    .autoCompleteWrapper {
      display: flex;
      flex-direction: column;
      margin: 0 16px 16px;
      gap: 16px;
      flex: unset;
    }

    .orderWrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      gap: 32px;

      .dateWrapper {
        display: flex;
        align-items: flex-start;
        padding-left: 16px;
      }
    }

    .selectWrapper,
    .booleanWrapper {
      margin: 0 16px 16px;
      flex: unset;
    }

    .booleanWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey-1;
      border-radius: 8px;
    }

    .accordionWrapper {
      padding: 16px;
    }

    .accordionContentWrapper {
      min-height: 100px;
      background-color: $color-white;

      .contentItemWrapper {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        height: 44px;

        &:hover {
          background-color: $color-white-1;
        }
      }
    }
  }
}