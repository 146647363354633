@import '@styles/resources';

.tableWrapper {
  margin-top: 15px;
  // overflow: auto;

  .sortTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .chipGroup {
    display: flex;
    justify-content: center;

    .chip {
      margin-right: 3px;
    }
  }

  .filterWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .rowWrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      margin-bottom: 24px;

      .selectLabel {
        width: 100%;
        min-width: 200px;
        max-width: 350px;
      }
    }

    .searchBar {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .search {
        width: 100%;
        min-width: 200px;
        max-width: 350px;
        height: 40px;
      }
    }
  }

  .viewEditDeleteButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-1;
    border-radius: 8px;
  }
}