@import '@styles/resources';

.mainWrapper {
  padding-block: 24px;
  padding-inline: 72px;
  overflow: scroll;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .invoiceAggregates {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #bcbcbc;

      .invoiceAggregatorWrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .tooltipContent {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .toolTipTitle {
            display: flex;
            flex-direction: row;
          }
        }

        .invoiceAggregatorText {
          display: flex;
          flex-direction: row;
          gap: 8px;
          font-size: 14px;
          line-height: 17px;
          color: $color-red-1;
          align-items: center;
        }
      }

      .divider {
        height: auto;
      }
    }

    .textWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .buttonWrapper {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .requestPaymentButton {
        width: fit-content;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .chipWrapper {
    display: flex;
    gap: 8px;
  }

  .paymentsTabWrapper {
    margin-top: 12px;
  }

  // .filterWrapper {
  //   margin-top: 12px;
  //   // width: 100%;
  //   display: flex;
  //   // justify-content: space-around;
  //   gap: 15px;

  // }
  .filterWrapper {
    margin-top: 12px;
    display: flex;
    gap: 15px;

    .selectLabel {
      width: 100%;
      margin-right: 12px;
    }
  }

  // .selectLabel {
  //   width: 350px;
  //   margin-right: 12px;
  // }
}