@import '@styles/resources';

.enquiryWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px 72px;
    gap: 16px;
    background-color: $color-white-1;
    flex: 1;
    overflow: auto;
    scroll-behavior: smooth;

    .enquiryHeaderWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .enquiryHeader {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;
        }
    }

    .internalEnquiry {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .enquiryFilterWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .enquirySelectFilter {
                display: flex;
                flex-direction: row;
                gap: 16px;
            }

            .enquiryPaginationFilter {
                display: flex;
                gap: 8px;
                align-self: center;
                justify-content: center;

                .pageOfTotalPage {
                    padding: 8px 8px 0 8px;
                    background-color: $color-grey-2;
                    border-radius: 16px;
                }
            }
        }

        .internalEnquiryContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 24px;
        }
    }

    .customerEnquiryWebContainer {
        display: block;

        .customerEnquiry {
            display: flex;
            flex-direction: row;
            gap: 16px;
            width: 100%;

            .customerEnquiryCardWrapper {
                display: flex;
                flex-direction: column;
                gap: 32px;
                width: 100%;
                padding: 28px 16px;
                align-items: center;
                border-radius: 16px;
                border: 1px solid #EAECF0;
                background: #FFF;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                overflow-y: auto;
                scroll-behavior: smooth;
                height: 80vh;
                justify-content: flex-start;


                &::-webkit-scrollbar {
                    width: 8px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-track-piece {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $color-grey-1;
                    border-radius: 8px;
                }
            }

            .customerEnquiryViewCreateEdit {
                width: 60%;
                flex-shrink: 0;
                align-self: stretch;
            }

            .createCustomerEnquiry {
                display: flex;
                flex-direction: row;
                gap: 16px;
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .customerEnquiryMobileContainer {
        display: none;

        .customerMobileWrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}

.selectWrapper {
    flex: unset;
    min-width: 150px;
}

@media (max-width: 700px) {
    .enquiryWrapper {
        padding: 16px;

        .customerEnquiryWebContainer {
            display: none;
        }

        .customerEnquiryMobileContainer {
            display: block;
        }
    }
}

@media (min-width: 701px) and (max-width: $lg-max) {
    .enquiryWrapper {
        padding: 16px;

        .customerEnquiryViewCreateEdit {
            width: 50% !important;
        }
    }
}