@import '@styles/resources';

.productWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 72px;
  background-color: $color-white-1;
  flex: 1;
  overflow: auto;
  scroll-behavior: smooth;

  .productHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .productTextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .productDivider {
    margin-top: 18px;
    margin-bottom: 20px;
  }

  .productFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .productFilterButton {
      width: 104px;
      height: 42px;
    }

    .productSearch {
      width: 350px;
      height: 40px;
    }

    .paginationFilter {
      display: flex;
      gap: 8px;
      align-self: center;
      justify-content: center;

      .pageOfTotalPage {
        padding: 8px 8px 0 8px;
        background-color: $color-grey-2;
        border-radius: 16px;
      }
    }
  }

  .productSection {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    margin-top: 24px;
    flex-grow: 1;

    .docHeaderItem {
      &:last-child {
        padding: 0;
      }
    }
  }

  .addProductButton {
    width: 125px;
    height: 42px;
  }
}

.productButton {
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.doucmentCountWrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  text-align: center;
}