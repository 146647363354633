@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 16px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;
      border-bottom: 1px solid $color-grey-1;
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .generateButton {
          border: 1px solid $color-red-1;
          color: $color-red-1;
          .Link {
            text-decoration: none;
            color: $color-red-1;
          }
        }
        .uploadWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .uploadWrapperNewBOE {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
      }
      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }
      .linkButton {
        padding: 10px 20px;
      }
      .pocInternalWrapper {
        display: flex;
        gap: 28px;
      }
    }
  }
  .accordion-chevron-wrapper {
    padding-right: 16px;
  }
  .orderCardFirstWrapper {
    display: flex;
    align-items: stretch;
    height: 100px;
    border-bottom: 1px solid $color-grey-1;
    padding-inline: 24px;
    .userCardContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
      .statusContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        padding-left: 24px;
      }
      .profileIcon {
        height: 40px;
        width: 40px;
      }
      .userCardDetailColumn {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        .titleWrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          .primaryTitleLabel {
            font-weight: $font-weight-400;
            font-size: $fs-16;
            color: $color-grey-6;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow-1;
    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.accordianContent {
  .docContainer {
    padding: 0px 24px;
    background: $color-grey-1;
  }
  .docContent {
    padding: 16px 24px;
  }
  .docTitle {
    color: $color-slate-brown-1;
  }
  .docContainer,
  .docContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-1;
    color: $color-slate-brown-1;
    position: relative;
    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .uploadDocWrapper {
        display: flex;
        flex-direction: row;
        gap: 19px;
        align-items: flex-start;
        color: $color-slate-brown-1;
      }
    }
  }
  .textWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: c;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
      align-self: stretch;
      margin-left: auto;
      border-left: 1px solid $color-grey-1;
      padding-left: 16px;
    }
  }
  .textWrapperNew {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .iconWrapper {
    position: absolute;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    .iconImg {
      display: inline-block;
      margin-right: 10px;
      vertical-align: bottom;
      cursor: pointer;
    }
  }
}

.underlineOnHover:hover {
  text-decoration: underline;
}