@import '@styles/resources';

.orderListingCardSkeletonWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px 5%;
    margin: 24px 0;
    background: $color-white;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    content-visibility: auto;
    contain-intrinsic-size: 302px;
    box-shadow: $box-shadow-1;
}

.orderListingCardSkeletonHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-grey-2;
}

.orderListingCardSkeletonHeaderLeft {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 40%;
}

.orderListingCardSkeletonHeaderRight {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 55%;
}

.orderListingCardSkeletonBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-2;
}

.orderListingCardSkeletonBodyRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-block: 16px;
    width: 48%;
}

.orderListingCardSkeletonBodyRowItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 30%;
}

.orderListingCardSkeletonFooter {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 16px;
}

.divider {
    height: auto;
    margin: 0 16px;
}

@media (max-width: 768px) {
    .orderListingCardSkeletonHeader {
        flex-direction: column;
        align-items: flex-start;
    }

    .orderListingCardSkeletonHeaderLeft,
    .orderListingCardSkeletonHeaderRight {
        width: 100%;
    }

    .orderListingCardSkeletonBody {
        flex-direction: column;
    }

    .orderListingCardSkeletonBodyRow {
        width: 100%;
    }

    .verticalDivider {
        display: none;
    }
}