@import '@styles/resources';

.showOnMobile {
  display: none;
}
.showOnWeb {
  display: block;
}

@media (max-width: $sm-max) {
  .showOnWeb {
    display: none;
  }
  .showOnMobile {
    display: block;
  }
}
