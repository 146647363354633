@import '@styles/resources';

.mainWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;

    .previewContainer {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .actionButtonWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .buttonContainer {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
}