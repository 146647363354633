@import '@styles/resources';

.mainWrapper {
    display: inline-flex;
    flex-direction: column;
    padding: 12px 72px 66px;
    gap: 20px;
    overflow-y: auto;
    background-color: var(--Elchemy-Primary-White, #FCFCFC);
    ;

    .container {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .basicDetails {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .headerText {
                    font-size: 28.066px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                }

                .headerButton {
                    display: flex;
                    flex-direction: row;
                    gap: 2px;
                    border-radius: 6px;
                    justify-content: center;
                    align-items: center;

                    .headerButtonText {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }
            }

            .basicDetailsContent {
                padding: 20px;
                border-radius: 8px;
                border: 1px solid #ECECEC;
                background: #FFF;
                width: 100%;
                display: grid;
                gap: 40px;

                .basicDetailsContentItem {
                    display: grid;
                    grid-template-columns: repeat(4, minmax(271px, 1fr));
                    gap: 20px;

                    .keyValue {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;

                        .key {
                            font-size: $fs-16;
                            font-weight: $font-weight-400;
                            color: $color-grey-6;
                            line-height: 19px;
                        }

                        .value {
                            font-family: $Mukta;
                            font-size: $fs-18;
                            font-weight: $font-weight-400;
                            color: $color-slate-brown-1;
                            line-height: 21.6px;
                        }
                    }
                }
            }

        }

        .productDetails {
            display: flex;
            flex-direction: column;
            gap: 10px;

            // .generatePDFButton {
            //     align-self: flex-end;
            // }
        }
    }


}