@import '@styles/resources';

.mainWrapper {
  display: flex;
  padding: 24px;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  min-height: 250px;
  overflow: scroll;
  .firstColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .editWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .buttonWrapper {
        cursor: pointer;
      }
      .dateWrapper {
        display: flex;
        .labelText {
          margin-top: 2px;
          margin-right: 6px;
        }
      }
    }
    .locationWrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 8px 0;
    }
    .contactWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 8px 0;
      gap: 8px;
      .numberWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
  .secondColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 16px;
    padding: 0 16px;
    border-left: 1px solid $color-grey-1;
    border-right: 1px solid $color-grey-1;
    .detailWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .text {
        font-weight: $font-weight-700;
        margin-right: 16px;
      }
      .buttonWrapper {
        cursor: pointer;
      }
    }
    .columnWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      .rowWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .textColumnWrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }
  .thirdColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 16px;
    .amountWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h6 {
        margin-bottom: 3px;
      }
      .titleWrapper {
        display: flex;
        gap: 10px;
        cursor: pointer;
      }
    }
    .orderWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .rowWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
