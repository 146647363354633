@import '@styles/resources';

.orderCardContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 371px;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  .orderCardFirstWrapper {
    display: flex;
    align-items: stretch;
    height: 100px;
    border-bottom: 1px solid $color-grey-1;
    padding-inline: 24px;
    .userCardContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-basis: 25%;
      .orderNameChip {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      .statusContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        padding-left: 24px;
      }
      .profileIcon {
        height: 40px;
        width: 40px;
      }
      .userCardDetailColumn {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
        .titleWrapper {
          display: flex;
          align-items: center;
          gap: 5px;
          .primaryTitleLabel {
            font-weight: $font-weight-400;
            font-size: $fs-16;
            color: $color-grey-6;
          }
        }
      }
    }
  }
  .progressBarCard {
    text-align: right;
    padding: 10px;
    position: relative;
    .editIcon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
  .progressBarWrapper {
    padding-top: 30px;
    width: 80%;
    margin: 0 auto;
  }
  .statusText {
    font-weight: 900;
    color: var(--color-slate-brown-3);
  }
  .cancelWrapper {
    background-color: $color-red-10;
    padding: 16px 24px;
    .cancelOrderTitle {
      font-size: 18px;
      font-weight: 700;
      color: $color-red-8;
      margin-bottom: 8px;
    }
  }

  .rowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    min-height: 93px;
    border-bottom: 1px solid $color-grey-1;
    &:last-child {
      border-bottom: 0;
    }
    .orderDetailWrapper {
      align-self: stretch;
      display: flex;
      align-items: stretch;
      flex: 1;
      flex-basis: 50%;
      padding: 16px 0;
      &:first-child {
        padding-right: 24px;
      }
      &:nth-child(2) {
        border-left: 1px solid $color-grey-2;
        padding-left: 24px;
      }
      .itemWrapper {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-basis: 50%;
        .iconWrapper {
          min-width: 40px;
          min-height: 40px;
        }
        .detailWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          gap: 4px;
          .supplierChipWrapper {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
    .progressBarCard {
      text-align: right;
      padding: 10px;
      position: relative;
      .editIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
    .progressBarWrapper {
      padding-top: 40px;
      width: 80%;
      margin: 0 auto;
    }
  }

  .rowWrapper:last-child {
    min-height: 200px;
  }

  .hoverText:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (max-width: $sm-max) {
  .orderCardContainer {
    padding: 0;
    min-height: auto;
    .orderCardFirstWrapper {
      display: block;
      height: auto;
      padding-block: 8px;
      .orderCardTitle {
        margin-bottom: 8px;
      }
      .orderCardDetailWrapper {
        flex-direction: column;
        align-items: stretch;
        display: flex;
      }
    }
  }
  .userCardContainer {
    margin-bottom: 12px;
  }
}
