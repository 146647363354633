@import '@styles/resources';

.createdAt {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.downloadLinks {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
}