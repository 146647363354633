@import '@styles/resources';

.orderWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  flex: 1;
  padding-block: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  scroll-behavior: smooth;
  overflow: auto;

  .orderIndividualHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .breadCrumbItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }

    .prevNextOrder {
      display: flex;
      flex-direction: row;

      .prevNextButtons {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
    }
  }
}

.refreshLink {
  text-align: center;
}

.refreshLink span {
  color: $color-red-1;
  cursor: pointer;
}

.refreshLink span:hover {
  text-decoration: underline;
}

@media (max-width: $sm-max) {
  .orderWrapper {
    padding-inline: 16px;

    .orderIndividualHeader {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: stretch;
      justify-content: stretch;

      .prevNextButtons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  margin: 16px;
  padding: 24px;

  @media (min-width: 768px) {
    margin: 24px;
    padding: 32px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid $color-grey-2;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media (min-width: 1024px) {
    padding: 32px;
  }

  .filterTabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
    border-bottom: 1px solid $color-grey-2;

    @media (min-width: 768px) {
      padding: 24px 0;
    }
  }
}