@import '@styles/resources';

.modalWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $color-white;
    margin: auto;
    outline: none;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    width: 540px;
    height: 80vh;
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
    }

    .modalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
        padding: 16px;
        background-color: $color-grey-1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        .closeButton {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .modalContent {
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow-y: auto;
        gap: 8px;

        &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-track-piece {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-1;
            border-radius: 8px;
        }

        .rowWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 30px;

            .keyValue {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 8px 0;
                min-width: 227px;

                .key {
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--Elchemy-Text-Secondary, #8D8885);
                    line-height: 120%;
                }

                .value {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 120%;
                }
            }

        }

        .productWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 10px;
            border: 1px solid $color-grey-2;
            border-radius: 8px;
        }
    }
}