@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .notesHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .notifyHeaderText {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
        }
    }

    .notesCard {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 24px;
    }
}

.notesSkeletonWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .notesTabFilter {
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
}

.notesSkeletonCard {
    display: flex;
    flex-direction: row;
    gap: 16px;
}