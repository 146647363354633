@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 72px;
    overflow: auto;
    scroll-behavior: smooth;



    .enquiryHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 23px;
        border-bottom: 1px solid #E2E2E2;

        .enquiryHeaderTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 27px;

            .titleTextIcon {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
        }
    }

    .enquiryFilters {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .filterSearchSelect {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            .searchBar {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;

                .search {
                    width: 350px;
                    height: 40px;
                }
            }
        }
    }

    .enquiryList {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
    }
}

.paginationFilter {
    display: flex;
    gap: 8px;
    align-self: center;
    justify-content: center;

    .pageOfTotalPage {
        padding: 8px 8px 0 8px;
        background-color: $color-grey-2;
        border-radius: 16px;
    }
}