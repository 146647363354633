@import '@styles/resources';

.addSupplierSidebarWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 281px;
  gap: 12px;

  .sidebarItem {
    padding: 8px 12px;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    background-color: $color-white;
    font-family: $Mukta;
    font-weight: $font-weight-400;
    font-size: $fs-18;
    color: $color-slate-brown-1;
    cursor: pointer;
    border: none;

    &:disabled {
      color: $color-grey-6;
      cursor: not-allowed;
    }

    &:hover {
      background-color: $color-grey-1;
    }

    gap: 16px;
  }

  .sidebarActiveItem {
    background-color: $color-grey-1;
  }
}

// @media screen and (max-width: $md-max) {
//   .addSupplierSidebarWrapper {
//     display: none;
//   }
// }

// @media screen and (min-width: $md-min) and (max-width: $lg-max) {
//   .addSupplierSidebarWrapper {
//     width: 180px;
//   }
// }
