@import '@styles/resources';

.supplierEmptyWrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  .supplierEmptyTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .noSupplierText {
      font-family: $Mukta;
      font-size: $fs-20;
      font-weight: $font-weight-700;
      color: $color-slate-brown-1;
    }
    .noSupplierSecondText {
      font-family: $Mukta;
      font-style: normal;
      font-weight: $font-weight-400;
      font-size: $fs-18;
      line-height: 22px;
      text-align: center;
      color: $color-grey-6;
    }
  }
  .addSupplierButton {
    width: 127px;
    height: 42px;
    align-self: center;
  }
}

@media (max-width: $sm-max) {
  .noSupplierText {
    font-size: $fs-16 !important;
  }
  .noSupplierSecondText {
    font-size: $fs-14 !important;
    line-height: 16px !important;
  }
}
