.paymentTermsLabel {
  margin-bottom: 8px;
}

.rowWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .fieldWrapper {
    margin-right: 24px;
    margin-bottom: 12px;
    width: 275px;

    @media (max-width: 768px) {
      width: 50%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}