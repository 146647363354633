@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 850px;
  max-height: 100%;
  overflow: hidden;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    overflow: hidden;

    .modalContent {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: $color-white;
      flex: 1;
      padding: 16px;
      overflow: auto;
      scroll-behavior: smooth;
      gap: 16px;

      .autoCompleteWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
      }

      .invoiceDetailsWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border: 1px solid $color-grey-1;
        border-radius: 8px;
        padding: 16px;

        .invoiceDetailsHeader {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          gap: 16px;
          width: 100%;

          .keyValue {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }
        }

        .invoiceDetails {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .rowWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      .rowWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
      }
    }

    .modalFooter {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background: $color-white;
      box-shadow: $box-shadow-1;
      height: 74px;

      & > button {
        flex: 1;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
  .hoverText {
    color: $color-grey-5;
    cursor: pointer;
    margin-top: 20px;
  }
  .hoverText:hover {
    text-decoration: underline;
  }
  .amountBreakdown {
    border-radius: 8px;
    border: 1px solid $color-grey-4;
    padding: 16px;
    margin-bottom: 12px;
    box-shadow: $box-shadow-1;
  }
}
