@import '@styles/resources';
.wrapper {
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  padding: 25px;
  margin-top: 25px;
  .mainWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 45px; // Position the line at a fixed point
      top: 0;
      bottom: 0;
      width: 2px;
      background: repeating-linear-gradient(
        to bottom,
        #ddd,
        #ddd 10px,
        transparent 10px,
        transparent 20px
      );
      z-index: 1;
    }
    .cardWrapper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      position: relative;
      z-index: 2;
      .card {
        background-color: #fff;
        padding: 10px 15px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        flex: 1;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        .cardTitle {
          display: flex;
          gap: 15px;
          .userName {
            font-weight: 900 !important;
            line-height: 21.6px;
          }
        }
        .profileIcon {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
      }
    }

    .cardWrapper + .cardWrapper {
      margin-top: 20px;
    }
    .cardWrapper:last-child {
      margin-bottom: 0; // Remove margin after the last card
    }
  }
}

.Skeletonwrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (max-width: $sm-max) {
}
