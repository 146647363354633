@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    // min-height: 100vh;
    position: relative;

    .formWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 23px;
        padding: 20px;
    }

    .productWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 23px;
        border: 1px solid $color-grey-2;
        border-radius: 8px;
        padding: 20px;

        .productHeaderIcons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            gap: 23px;
        }
    }

    .rowWrapper {
        display: flex;
        flex-direction: row;
        gap: 23px;
        width: 100%;

        >* {
            flex: 1 1 0;
        }
    }

    .rowWrapperHalf {
        display: flex;
        flex-direction: row;
        gap: 23px;
        width: 100%;

        >* {
            width: 48%;
        }
    }

    .actionButtons {
        width: 700px;
        position: fixed;
        bottom: 0;
        z-index: 10;
        background-color: $color-grey-1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
    }
}