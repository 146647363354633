@import '@styles/resources';

.boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    padding: 16px;

    .itemDetailsContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 8px;

        .fieldTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            margin-bottom: 10px;
        }

        .fieldTable {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            border: 1px solid $color-grey-2;
            border-radius: 8px;

            .tableHeader,
            .tableContent {
                display: grid;
                grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
                padding: 12px 24px 12px 16px;
                background-color: $color-grey-1;
            }

            .tableContent {
                background-color: $color-white;
                border-bottom: 1px solid $color-grey-2;

                .secondaryText {
                    font-family: $Mukta;
                    font-size: $fs-16;
                    font-weight: $font-weight-500;
                    color: $color-grey-6;
                    line-height: 19px;
                    margin-top: 12px;
                }
            }

            .gstDetails,
            .totalContainer {
                display: grid;
                grid-template-columns: 4fr 1fr;
                padding: 16px 24px;
            }

            .totalContainer {
                background-color: $color-grey-1;
            }
        }

        .fieldWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .orderDetailsContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 16px;
        gap: 24px;
        border: 1px solid $color-grey-2;
        border-radius: 8px;

        .fieldTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
            margin-bottom: 10px;
        }

        .fieldWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.actionButtonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonContainer {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.documentWrapper {
    width: 100%;
}

.bottomDetails {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.ItemDetailsPreview {
    display: flex;
    flex-direction: column;
}