.chipGroup {
  display: flex;

  .chip {
    margin-right: 3px;
  }
}

.display_name {
  display: flex;
  align-items: center;
  justify-content: center;

  .docName {
    cursor: pointer;
  }

  .docName:hover {
    text-decoration: underline;
  }

  .systemGenerated {
    margin-left: 5px;
    height: 15px;
    width: 15px;
    margin-bottom: 4px;
  }
}

.tableIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .divider {
    width: 1px;
    height: 25px;
  }

  .resolveInconsistencyBtn {
    font-size: 12px;
  }
}

.DocumentNameTableHeader,
.UploadedOnTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.DocumentTabProducts {
  display: flex;
  justify-content: center;
}