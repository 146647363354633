@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;

  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 24px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      .linkButton {
        font-size: 16px;
      }

      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .uploadWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }
    }
  }

  .accordianContent {
    display: flex;
    flex-direction: row;
    // padding: 16px 24px;
    justify-content: space-between;

    //border-bottom: 1px solid $color-grey-1;
    .rightButtonWrapper {
      display: flex;
      align-items: center;
      padding: 16px 24px;

      .saveButton {
        width: 180px;
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
        margin-right: 20px;
      }
    }

    .dateTimeWrapper {
      display: flex;
      flex-direction: row;
      gap: 48px;
      padding: 16px 24px;

      .dateWrapper {
        display: flex;
        flex-direction: column;
        max-width: 150px;

        .datePicker {
          padding-bottom: 6px;
        }
      }

      .timeWrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;

        .alignText {
          padding-top: 20px;
          color: $color-slate-brown-1;
        }

        .colorColon {
          color: $color-slate-brown-1;
        }

        .fromTime,
        .toTime {
          display: flex;
          flex-direction: column;

          .timeInnerWrap {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            padding-top: 6px;

          }
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.underlineOnHover:hover {
  text-decoration: underline;
}