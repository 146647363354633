@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8.771px;
    padding: 21px;
    border: 1px solid #ECECEC;
    gap: 23px;

    .header {
        border-bottom: 1px solid #E2E2E2;
        font-size: 17.5px;
        color: var(--Elchemy-Primary-Slate-Brown, #2A2522);
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        padding-bottom: 3.5px;
    }

    .formWrapper {
        display: flex;
        flex-direction: column;
        gap: 23px;

        .rowWrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 23px;

            .fxRate {
                padding-left: 4px;
                font-weight: $font-weight-400;
                color: $color-grey-6;
                line-height: 19px;
            }
        }
    }
}