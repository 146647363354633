@import '@styles/resources';

.enquiryWrapper {
  padding: 24px;
  background-color: $color-white;
  overflow-y: scroll;
}

.enquiryHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.enquiryHeader {
  display: flex;
  align-items: center;
  gap: 16px;
}

.internalEnquiry {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.enquiryTabWrapper {
  margin-bottom: 16px;
}

.enquiryFilterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.enquirySelectFilter {
  display: flex;
  gap: 16px;
}

.enquiryPaginationFilter {
  display: flex;
  align-items: center;
  gap: 16px;
}

.internalEnquiryContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.cardSkeleton {
  padding: 16px;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: $box-shadow-1;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.cardBody {
  margin-bottom: 16px;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardActions {
  display: flex;
  gap: 8px;
}

.paginationWrapper {
  margin-top: 24px;
}

@media (max-width: 1024px) {
  .internalEnquiryContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .enquiryWrapper {
    padding: 16px;
  }

  .enquiryFilterWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .enquirySelectFilter {
    flex-direction: column;
    width: 100%;
  }
}