.createEnquiryContainerWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .createEnquiryPlaceOfDelivery {
        .placeOfDeliveryFieldSpacing {
            width: 100%;
        }
    }

    .createEnquiryProductWrapper {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        align-items: flex-start;
        gap: 24px;
        flex-wrap: wrap;
    }

    .createEnquiryDownloadWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 16px;

        .createEnquiryDocumentUpload {
            width: 100%;
        }

        .fileUploadState {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    .createEnquiryRemarksWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 12px;

        .createEnquiryRemarks {
            width: 100%;
            display: flex;
            gap: 8px;
            flex: 1 0 0;
        }

    }
}