@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 23px;

    .formWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 23px;
        padding: 20px;
    }

    .rowWrapper {
        display: flex;
        flex-direction: row;
        gap: 23px;
        width: 100%;

        >* {
            flex: 1 1 0;
        }
    }

    .rowWrapperHalf {
        display: flex;
        flex-direction: row;
        gap: 23px;
        width: 100%;

        >* {
            width: 48%;
        }
    }

    .actionButtons {
        width: 100%;
        position: sticky;
        bottom: 0;
        z-index: 10;
        background-color: $color-grey-1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
    }
}