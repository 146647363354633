@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .productWrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        border-bottom: 1px solid #ECECEC;
        gap: 16px;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .title {
                font-size: 18px;
                font-family: Mukta;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                padding-right: 24px;
                border-right: 1px solid #ECECEC;
            }

            .buttonWrapper {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: center;
            padding: 10px;
            ;

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                width: 100%;

                .keyValue {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    text-align: center;
                    min-width: 150px;
                    gap: 4px;
                    border-left: 1px solid #ECECEC;
                    padding-left: 10px;

                    .key {
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 120%;
                        color: #8D8885;
                    }

                    .value {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 120%;
                        color: #2A2522;
                        font-family: Mukta;
                    }
                }

                .checkBoxKeyValue {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;

                    .checkBox {
                        display: flex;
                        flex-direction: row;

                        .checkboxLabel {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;
                        }
                    }

                    .checkedKeyValue {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        text-align: center;
                        min-width: 150px;
                        gap: 4px;

                        .key {
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 120%;
                            color: #8D8885;
                        }

                        .value {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 120%;
                            color: #2A2522;
                            font-family: Mukta;
                        }
                    }
                }

                .rowIcons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;
                }
            }
        }

    }
}