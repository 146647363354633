@import '@styles/resources';

.mainWrapper {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  overflow: hidden;
  content-visibility: auto;
  contain-intrinsic-size: 302px;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sideWrapper {
      flex-basis: 60%;
      align-self: stretch;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
    .buttonWrapper {
      align-self: stretch;
      padding: 0 16px;
      border-left: 1px solid $color-grey-2;
      text-align: center;
      vertical-align: middle;
      display: flex;
      align-items: center;
    }
    .productNameWrapper {
      display: flex;
      justify-content: space-around;
      .productNameSecondPart {
        margin-left: 6px;
      }
    }
    .productName {
      line-height: 30px;
      margin-bottom: 10px;
    }
  }

  .rowWrapper,
  .progressBarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    min-height: 93px;
    border-bottom: 1px solid $color-grey-1;
    &:last-child {
      border-bottom: 0;
    }
    .orderDetailWrapper {
      align-self: stretch;
      display: flex;
      align-items: stretch;
      flex: 1;
      flex-basis: 50%;
      padding: 16px 0;
      &:first-child {
        padding-right: 24px;
      }
      &:nth-child(2) {
        border-left: 1px solid $color-grey-2;
        padding-left: 24px;
      }
      .itemWrapper {
        display: flex;
        align-items: center;
        gap: 6px;
        flex-basis: 50%;
        .iconWrapper {
          min-width: 40px;
          min-height: 40px;
        }
        .detailWrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          gap: 4px;
          .supplierChipWrapper {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
      }
    }
    .progressBarCard {
      text-align: right;
      padding: 10px;
      position: relative;
      .editIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  .rowWrapper:last-child {
    min-height: 200px;
  }

  .columnWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: 1px solid $color-grey-1;
    padding: 16px 24px;
    gap: 8px;
  }

  .orderCardLink {
    cursor: pointer;
  }

  .cancelWrapper {
    background-color: $color-red-10;

    .cancelOrderTitle {
      font-size: 18px;
      font-weight: 700;
      color: $color-red-8;
    }
  }

  .disable {
    background-color: $color-grey-1;
    h3 {
      color: $color-grey-6;
    }
  }
  .hoverText:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .progressBarWrapper {
    padding-block: 40px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: $sm-max) {
  .columnWrapper {
    display: block;
    .headerWrapper {
      display: block;
      .productNameWrapper {
        display: block;
        .productNameSecondPart {
          margin-left: 0;
        }
      }
    }
  }
  .rowWrapper {
    display: block !important;
    padding: 0px !important;

    .orderDetailWrapper {
      padding: 0;
      &:first-child {
        padding: 16px 24px !important;
      }
      &:nth-child(2) {
        border: 0;
        padding: 16px 24px !important;
      }
    }
  }
  .progressBarWrapper {
    padding: 24px 0 !important;
    display: block;
  }
}
