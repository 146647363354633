@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(91, 91, 91, 0.10);
    border: 1px solid #E2E2E2;
    width: 100%;

    .EnquiryHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 16px;

        .EnquiryHeaderText {
            display: grid;
            grid-template-columns: repeat(7, 1fr) auto; //One more than required to give some space between text and button
            align-items: center;
            gap: 24px;

            .keyValue {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
                border-left: 1px solid #ECECEC;
                padding-left: 24px;

                &:first-of-type {
                    border-left: none;
                    padding-left: 0;
                }
            }

            .filterStates {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 8px;

                .chips {
                    width: 200px;
                }
            }
        }

        .EnquiryHeaderButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
        }
    }

    .ConsolidationProductWrapper {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 16px;

        .ConsolidationHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 16px;

            .buttonWrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
            }
        }

        .ProductDetails {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            align-items: center;
            gap: 24px;

            .keyValue {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
                justify-content: flex-start;
                border-left: 1px solid #ECECEC;
                padding-left: 24px;

                .productName {
                    font-size: 16px;
                    font-weight: 700;
                }

                .keyValueText {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                }

                &:first-of-type {
                    border-left: none;
                    padding-left: 0;
                }
            }
        }
    }

    .ProductWrapper {
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 16px;

        .ProductDetails {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            align-items: center;
            gap: 24px;

            .keyValue {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
                justify-content: flex-start;
                border-left: 1px solid #ECECEC;
                padding-left: 24px;

                .productName {
                    font-size: 16px;
                    font-weight: 700;
                }

                .keyValueText {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                }

                &:first-of-type {
                    border-left: none;
                    padding-left: 0;
                }
            }
        }

        .ProductDetails {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            align-items: center;
            gap: 24px;

            .keyValue {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
                justify-content: flex-start;
                border-left: 1px solid #ECECEC;
                padding-left: 24px;

                .productName {
                    font-size: 16px;
                    font-weight: 700;
                }

                .keyValueText {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                }

                &:first-of-type {
                    border-left: none;
                    padding-left: 0;
                }
            }
        }


        .ProductView {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 40px;
        }
    }

    .badgeWrapper {
        position: relative;
        bottom: 13px;
        right: 38vw;

        .badge {
            border: 1px solid #E2E2E2;
            background: #FFF;
            box-shadow: 0px 4px 14px 0px rgba(91, 91, 91, 0.10);
            min-width: 110px;
            background-color: $color-white-1;
            color: $color-red-2;
            cursor: pointer;
        }
    }
}