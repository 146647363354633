@import '@styles/resources';

.formWrapper {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .actionBtnWrapper {
    margin-top: auto;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nextBtn {
      margin-right: 16px;
    }
  }

  .itemWrapper {
    padding: 16px;

    .fieldWrapper {
      flex: 1;
      display: flex;
    }

    .pacakageSectionWrapper {
      margin-top: 24px;

      .titleWrapper {
        margin-bottom: 8px;
      }
    }

    .boxWrapper {
      //   margin-bottom: 24px;
      border-radius: 8px;
      border: 1px solid var(--color-grey-2);
      padding: 16px;

      .rowWrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin-bottom: 16px;

        .fieldWrapper {
          margin-right: 24px;
        }

        .fieldWrapper1 {
          border-radius: 0;
          width: 125px;
        }

        .fieldWrapper2 {
          margin-right: 24px;
        }
      }
    }
  }

  .fieldWrapper {
    width: 100%;
  }
}