@import '@styles/resources';

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  margin: auto;
  outline: none;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  width: 600px;
  max-height: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-1;
    border-radius: 8px;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 16px;
    background-color: $color-grey-1;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    .closeButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    flex: 1;

    .modalContent {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      background-color: $color-white;
      overflow: auto;
      flex: 1;
      padding: 16px;
      gap: 12px;

      .typeField {
        flex: 0;
      }

      .fileUploadState {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 12px;
        height: 40px;
        background: $color-white;
        border: 1px solid $color-grey-2;
        border-radius: 8px;
        overflow: hidden;

        .fileName {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 14px;
        }

        .deleteButton {
          border-left: 1px solid $color-grey-2;
          padding: 0 10px;
          cursor: pointer;
        }
      }

      .rowWrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        justify-content: space-between;
      }
    }

    .modalFooter {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background: $color-white;
      box-shadow: $box-shadow-1;
      height: 74px;

      & > button {
        flex: 1;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}
