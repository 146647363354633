.taskChipWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.trackingContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}