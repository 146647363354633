.mainWrapper {
  margin-bottom: 24px;
  .boxWrapper {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .rowWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      .fieldWrapper {
        margin-right: 12px;
        margin-left: 12px;
      }
    }
    .containerWrapper {
      margin-bottom: 12px;
      .titleWrapper {
        margin-bottom: 12px;
      }

      .headerWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      .container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: flex-start;
        .rowWrapper {
          flex: 1 1 calc(33.333% - 16px); // Adjust 16px based on the gap
          margin-bottom: 12px; // Margin bottom for each item
          box-sizing: border-box;
          max-width: 33%;
        }
      }
    }
  }
}
