@import '@styles/resources';

.modalWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $color-white;
    outline: none;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    width: 700px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-grey-1;
        border-radius: 8px;
    }

    .modalHeader {
        position: sticky;
        top: 0;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
        padding: 16px;
        background-color: $color-grey-1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        .closeButton {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    .modalContent {
        flex: 1;
        overflow-y: auto;
        width: 100%;

        &::-webkit-scrollbar {
            width: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-track-piece {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-1;
            border-radius: 8px;
        }
    }
}