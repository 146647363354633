@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px 72px 0;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;

    .buttonWrapper {
      width: 111px;
      height: 42px;
    }
  }
}

.ViewCustomerSkeletonWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;

  .ViewCustomerSkeletonHeader {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $color-grey-2;
    border-radius: 8px;
    padding: 16px;

    .ViewCustomerSkeletonHeaderLeft {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .ViewCustomerSkeletonHeaderDate {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
    }

    .ViewCustomerSkeletonHeaderCenter {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-left: 1px solid $color-grey-2;
      padding: 16px;

      .ViewCustomerSkeletonHeaderCenterDetails {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .ViewCustomerSkeletonHeaderCenterDetailsObject {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }

    .ViewCustomerSkeletonHeaderRight {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-left: 1px solid $color-grey-2;
      padding: 16px;
    }
  }

  .ViewCustomerSkeletonBody {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    .ViewCustomerSkeletonBodyUpper {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .ViewCustomerSkeletonFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ViewCustomerSkeletonFooterTabs {
      display: flex;
      flex-direction: row;
      gap: 16px;
      border-bottom: 1px solid $color-grey-2;
      padding: 16px;
    }
  }
}

.divider {
  height: auto;
}