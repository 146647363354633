@import '@styles/resources';

.mainWrapper {
    display: flex;
    flex-direction: column;

    .mainWrapperLabel {
        font-family: $Mukta;
        font-weight: $font-weight-400;
        font-size: $fs-16;
        color: $color-slate-brown-1;
        margin-bottom: -18px;

        &::after {
            content: attr(data-value);
            color: $color-red-1;
            font-family: $Mukta;
            font-size: $fs-16;
            font-weight: $font-weight-400;
        }
    }

    .contentWrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .contentWrapperInput {
            width: calc(100% - 100px);
        }

        .contentWrapperUnit {
            width: 120px;
        }
    }
}