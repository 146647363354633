@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  content-visibility: auto;
  contain-intrinsic-size: 246px;
  &:last-of-type {
    margin-bottom: 16px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 16px 24px;
    border-bottom: 1px solid $color-grey-1;
  }
  .displayId {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 0 24px;
  }
  .textWrapper {
    padding: 16px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
      align-self: stretch;
      margin-left: auto;
      border-left: 1px solid $color-grey-1;
      padding-left: 16px;
    }
    .addressWrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .mainContent {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-grey-1;
    .pocWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      .poctextWrapper {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    .ecgcColumn {
      align-self: stretch;
      margin-left: auto;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 4px;
      border-left: 1px solid $color-grey-1;
    }
  }

  .customerCardLink {
    text-decoration: none !important;
  }
}
