@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderTextWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
.actionButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.orderFormContainer {
  padding: 20px 0px;
}
