@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionHeader {
    border-bottom: 1px solid $color-grey-1;
    padding: 0 16px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0px;
      width: 100%;
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .linkButton {
        font-size: 16px;
      }

      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .uploadWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .assignButton {
          border: 1px solid $color-red-1;
          padding: 10px 20px;
        }

        .btnContainer {
          display: flex;
          gap: 8px;
        }
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }
    }
  }
  .accordionContent {
    .orderCardFirstWrapper {
      display: flex;
      align-items: stretch;
      height: 100px;
      border-bottom: 1px solid $color-grey-1;
      padding-inline: 24px;
      .userCardContainer {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        .statusContainer {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 4px;
          padding-left: 24px;
        }
        .profileIcon {
          height: 40px;
          width: 40px;
        }
        .userCardDetailColumn {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 4px;
          .titleWrapper {
            display: flex;
            align-items: center;
            gap: 5px;
            .primaryTitleLabel {
              font-weight: $font-weight-400;
              font-size: $fs-16;
              color: $color-grey-6;
            }
          }
        }
      }
    }
    .mainContent {
      .contentWrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $color-grey-2;
        border-right: 1px solid $color-grey-2;
        .primaryTitle {
          margin: 24px 0 0 24px;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.underlineOnHover:hover {
  text-decoration: underline;
}