@import '@styles/resources';

.supplierWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
  gap: 24px;

  .supplierTabContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.supplierIndividualSkeletonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 24px;

  .supplierIndividualSkeletonHeader {
    display: flex;
    flex-direction: row;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid $color-grey-2;
    align-items: center;
    padding: 16px;
    // margin-right: 64px;


    .divider {
      height: 100px;
      // width: 1x;
      margin: 16px 0;
    }

    .supplierIndividualSkeletonHeaderLeft {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    .supplierIndividualSkeletonHeaderRight {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .suppplierIndividualSkeletonBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    .suppplierIndividualSkeletonBodyUpper {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .supplierIndividualSkeletonFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .supplierIndividualSkeletonFooterUpper {
      display: flex;
      flex-direction: row;
      gap: 16px;
      border-bottom: 1px solid $color-grey-2;
      padding-bottom: 8px;
      border-radius: 8px;
    }

    .supplierIndividualSkeletonFooter {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;

      .supplierIndividualSkeletonSidebar {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .supplierIndividualSkeletonDetails {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;
        padding: 16px;
        border: 1px solid $color-grey-2;
        border-radius: 8px;

        .supplierIndividualSkeletonDetailsBody {
          display: flex;
          flex-direction: row;
          gap: 24px;
          justify-content: space-between;

          .supplierIndividualSkeletonDetailsBodyObject {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          .supplierIndividualSkeletonDetailsBodyWrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;

            .supplierIndividualSkeletonDetailsBodyObject {
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
          }


        }
      }
    }
  }
}

.divider {
  height: auto;
}