@import '@styles/resources';

.enquiryProductContainer {
    display: flex;
    flex-direction: column;
    align-self: stretch;

    .headerWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .enquiryProducts {
        display: flex;
        flex-direction: row;
        gap: 16px;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        .enquiryProductsName {
            width: 100%;

            .productNameFieldSpacing {
                width: 100%;
            }
        }

        .enquiryProductsPriceQuantity {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }



        .enquiryProductsPrice,
        .enquiryProductsQuantity {
            display: flex;
            flex-direction: row;
            gap: 16px;
        }
    }
}

.selectWrapper {
    width: 100px;
    flex: unset;
}

.fieldSpacing {
    min-width: 120px;
}

@media (max-width: $lg-max) {
    .enquiryProductContainer {
        .enquiryProducts {
            .enquiryProductsPriceQuantity {
                flex-direction: column;
                gap: 16px;
            }
        }
    }
}