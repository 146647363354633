@import '@styles/resources';
$image-base-path: '../../../../src/assets/images/';
// //$image-base-path: '@assets/images';
// .accomplished {
//   color: #8d8885;
//   padding-bottom: 80px;
//   max-width: 100px;
//   background-image: url('#{$image-base-path}/progress-bar-blue-dot.svg');
//   //background-image: url('http://127.0.0.1:5500/src/assets/images/progress-bar-blue-dot.svg');
//   background-repeat: no-repeat;
//   background-position: center;
//   text-align: center;
//   // margin-bottom: 5px;
// }
// .accomplished:hover {
//   color: #8d8885;
//   padding-bottom: 80px;
// }

// .notAccomplished {
//   color: #8d8885;
//   padding-bottom: 80px;
//   max-width: 100px;
//   background-image: url('#{$image-base-path}/progress-bar-gray-dot.svg');
//   //background-image: url('http://127.0.0.1:5500/src/assets/images/progress-bar-gray-dot.svg');
//   background-repeat: no-repeat;
//   background-position: center;
//   text-align: center;
//   // margin-bottom: 5px;
// }

// .notAccomplished p {
//   margin-bottom: 15px;
// }

.secondaryText {
  text-align: center;
  font-size: 14px;
}

.primaryText {
  font-family: $Mukta;
}

.currentState {
  font-weight: 900;
  position: relative;
  top: -30px;
}

.progressBarStep {
  width: 70px;
  height: 70px;
  position: relative;
  top: -20px;
}
