@import '@styles/resources';

.partiesFormWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    gap: 16px;

    .formActionWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 16px;

        .saveAndProceedButton {
            width: fit-content;
            padding-left: 8px;
            padding-right: 8px;
        }
    }

    .accordian {
        display: flex;
        flex-direction: column;
        border: 1px solid $color-grey-1;
        border-radius: 8px;
        gap: 16px;
        padding: 16px;
        margin-top: 16px;
        box-shadow: $box-shadow-1;
    }

    .accordianError {
        display: flex;
        flex-direction: column;
        border: 1px solid $color-grey-1;
        border-radius: 8px;
        gap: 16px;
        padding: 16px;
        margin-top: 16px;
        box-shadow: $box-shadow-red-1;
    }

    .accordianHeader {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        justify-content: space-between;
        gap: 16px;
        height: 60px;

        .accordianHeaderDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .invoiceNumber {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
            }

            .error {
                color: $color-red-1;
            }
        }

        .dateButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
        }
    }

    .rowWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 12px 24px;
        gap: 16px;
    }

    .paymentData {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .invoicePaymentWrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            border: 1px solid $color-grey-1;
            border-radius: 8px;
            padding: 16px;

            .paymentHeader {
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;

                .payment {
                    font-weight: 600;
                }
            }

            .rowWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
            }
        }
    }

    .itemErrors {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-bottom: 24px;
        font-weight: 600;

        .helperTextClass {
            color: $color-red-1 !important;
        }
    }
}

.keyValue {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}