@import '@styles/resources';

.cardWrapper {
    display: flex;
    flex-direction: column;
    // align-self: stretch;
    gap: 16px;
    width: 416px;
    border-radius: 8px;
    background: var(--elchemy-white-fff, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
    padding: 16px;

    .cardHeader {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .cardHeaderEditDelete {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            .closeButton :hover {
                cursor: pointer;
            }
        }
    }

    .cardCreaterInfo {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .labelValue {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}

.notesDivider {
    height: auto;
}