@import '@styles/resources';

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 24px;
  padding-inline: 72px;
  background-color: $color-white-1;
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .textWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .textWrapperExternal {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 16px;
    }

    .addOrderButton {
      width: 127px;
      height: 42px;
    }
  }

  .tabsWrapper {
    margin: 24px 0;
  }

  .filterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    .filterButtonWrapper {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .filterButton {
      width: 104px;
      height: 42px;
    }

    .searchBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      .search {
        width: 350px;
        height: 40px;
      }
    }

    .paginationFilter {
      display: flex;
      gap: 8px;
      align-self: center;
      justify-content: center;

      .pageOfTotalPage {
        padding: 8px 8px 0 8px;
        background-color: $color-grey-2;
        border-radius: 16px;
      }
    }
  }

  .orderSection {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
  }
}

.orderListingCardSkeletonWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 24px;
  padding-inline: 72px;
  margin: 24px;
  background: $color-white;
  border: 1px solid $color-grey-2;
  border-radius: 8px;
  content-visibility: auto;
  contain-intrinsic-size: 302px;
  box-shadow: $box-shadow-1;

  .orderListingCardSkeletonHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-grey-2;

    .orderListingCardSkeletonHeaderLeft {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .orderListingCardSkeletonHeaderRight {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  .orderListingCardSkeletonBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-2;

    .orderListingCardSkeletonBodyRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      padding-block: 16px;
    }
  }

  .orderListingCardSkeletonFooter {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 16px;
  }

  .divider {
    height: auto;
    margin: 0 16px;
  }
}

.supplierFilterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filterButtonWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .supplierFilterButton {
    width: 104px;
    height: 42px;
  }

  .supplierSearch {
    width: 350px;
    height: 40px;
  }
}

@media (max-width: $sm-max) {
  .mainWrapper {
    padding-inline: 18px;

    .searchBar {
      margin-left: auto;
      margin-right: auto;

      .search {
        width: 100%;
      }
    }

    .searchDataWrapper {
      display: flex;
      margin-top: 18px;
      justify-content: space-between;
    }
  }

  .paginationFilter {
    display: flex;
    gap: 8px;
    align-self: center;
    justify-content: center;

    .pageOfTotalPage {
      padding: 8px 8px 0 8px;
      background-color: $color-grey-2;
      border-radius: 16px;
    }
  }
}
