@import '@styles/resources';
.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-white;
  box-shadow: $box-shadow-1;
  border-radius: 8px;
  margin-top: 16px;
  .accordionMainHeader {
    margin-bottom: 16px;
    background: $color-white;
    box-shadow: $box-shadow-1;
  }

  .accordionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    background: $color-white;
    border-bottom: 1px solid $color-grey-2;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      .pocWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;

        .uploadWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .divider {
        height: 38px;
        border-right: 1px solid $color-grey-1;
      }

      .editButton {
        color: $color-slate-brown-1;
      }

      .uploadButton {
        color: $color-red-1;
        border: 1px solid $color-red-1;
        padding: 10px 20px;
        border-radius: 8px;
      }
    }
  }

  .accordionContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .titleWrapper {
      padding: 20px 24px;
    }

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0px 24px 20px 24px;

      .textWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .textWrapper,
    .textWrapperNew {
      padding: 16px 24px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .textWrapperNew {
      justify-content: flex-end;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
}

.underlineOnHover:hover {
  text-decoration: underline;
}